/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import useGames from './hooks/useGames';

function GameList({ setActiveGameId }) {
  const {
    status, data: games, error, isFetching,
  } = useGames();
  // const [createPost, { status: createPostStatus }] = useCreatePost()

  return (
    <section>
      <div>
        <div>
          {status === 'loading' ? (
            <span>Loading...</span>
          ) : status === 'error' ? (
            <span>
              Error:
              {' '}
              {error.message}
            </span>
          ) : (
            <>
              <h3>
                Saved Games
                {' '}
                {isFetching ? <small>Updating...</small> : null}
              </h3>
              <div>
                {games.map((game) => (
                  <div key={game.id}>
                    <h4>{game.name}</h4>
                    <div className="flex-parent-element">
                      <div className="flex-child-element">
                        {/* eslint-disable-next-line no-console */}
                        <button type="button" onClick={() => setActiveGameId(game.id)}>
                          Load
                        </button>
                      </div>
                      <div className="flex-child-element">
                        {/* eslint-disable-next-line no-console */}
                        <button type="button" onClick={() => console.log('Implement this button action.')}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <hr />
      <div>
        <h3>Start New Game</h3>
        <div>
          {/* eslint-disable-next-line no-console */}
          <button type="button" onClick={() => console.log('Implement this button action.')}>
            New Game
          </button>
        </div>
      </div>
    </section>
  );
}

GameList.propTypes = {
  setActiveGameId: PropTypes.func.isRequired,
};

export default GameList;
