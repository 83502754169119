import { useQuery } from 'react-query';
import axios from 'axios';

/*
Testing requirements? - NONE
*/

const endpoint = window?.env_vars?.REACT_APP_SERVICE_URL ? window.env_vars.REACT_APP_SERVICE_URL : 'http://localhost:5024';

export default function useGames() {
  return useQuery('games', () => axios.get(`${endpoint}/api/games`).then((res) => res.data));
}
