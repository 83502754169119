import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './App.css';
import { Wrapper, Sidebar, Main } from 'components/styled';
import Game from './Game';
import GameList from './GameList';

// Create a react-query client
const queryClient = new QueryClient();

function App() {
  const [activeGameId, setActiveGameId] = React.useState();

  // This is just for testing.  Clean this up later
  const endpoint = window?.env_vars?.REACT_APP_SERVICE_URL ? window.env_vars.REACT_APP_SERVICE_URL : 'http://localhost:5024';

  return (
  // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <Wrapper>
        <Sidebar>
          <GameList setActiveGameId={setActiveGameId} />
        </Sidebar>
        <Main>
          <Game activeGameId={activeGameId} />
          <p />
          <p>
            window.env_vars.REACT_APP_SERVICE_URL:
            {' '}
            {window.env_vars.REACT_APP_SERVICE_URL}
          </p>
          <p>
            endpoint:
            {' '}
            {endpoint}
          </p>
        </Main>
      </Wrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
