import PropTypes from 'prop-types';
/*
Testing requirements?  Not really any logic, so nothing here to test.
*/

function Square({ onClick, value }) {
  return (
    <button type="button" className="square" onClick={onClick}>
      {value}
    </button>
  );
}

Square.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
};

Square.defaultProps = {
  value: null,
};

export default Square;
