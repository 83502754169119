// Disable this while you learn
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import Board from './Board';

import useGames from './hooks/useGames';
import useGame from './hooks/useGame';
import useSaveGame from './hooks/useSaveGame';
import useUpdateSavedGame from './hooks/useUpdateSavedGame';
import useDeleteSavedGame from './hooks/useDeleteSavedGame';

/*
Testing requirements?

- calculateWinner needs tests.
- boardIsFull = single line function, no tests needed.
*/

const NO_WINNER = '!';

function boardIsFull(squares) {
  // squares array will contain nulls if there are empty squres
  return !squares.includes(null);
}

// TODO: Create some unit tests for calculateWinner
function calculateWinner(squares) {
  /* Returns:
      X    - if X was the winner
      0    - if O was the winner
      null - if no winner (yet)
      !    - if there can be no winner (board is complete and no winning sequence)
    */
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i += 1) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      // There is a winner!
      return squares[a];
    }
  }

  // No winner so-far...

  if (boardIsFull(squares)) return NO_WINNER;
  return null;
}

// ========================================

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [{
        squares: Array(9).fill(null),
      }],
      stepNumber: 0,
      xIsNext: true,
    };
  }

  handleClick(i) {
    const { history: stateHistory, stepNumber, xIsNext } = this.state;
    const history = stateHistory.slice(0, stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();
    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    squares[i] = xIsNext ? 'X' : 'O';
    this.setState({
      history: history.concat([{
        squares,
      }]),
      stepNumber: history.length,
      xIsNext: !xIsNext,
    });
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: (step % 2) === 0,
    });
  }

  render() {
    const { history, stepNumber, xIsNext } = this.state;
    const current = history[stepNumber];
    const winner = calculateWinner(current.squares);

    // Render a list of all the moves as buttons to enable traversing history.
    const moves = history.map((step, move) => {
      const desc = move
        ? `Go to move #${move}`
        : 'Go to move #0';
      return (
        // Array's make for bad Keys because they are circumstantually unique, but for this case, it's fine.
        // eslint-disable-next-line react/no-array-index-key
        <li key={move}>
          <button type="button" onClick={() => this.jumpTo(move)}>{desc}</button>
        </li>
      );
    });

    // Display status of the game.  Either the winner, or the person whose next move it is.
    let status;
    if (winner) {
      status = (winner === NO_WINNER ? 'Game over, and it\'s a draw!' : `${winner} wins!`);
    } else {
      status = `Next player: ${xIsNext ? 'X' : 'O'}`;
    }

    return (
      <>
        <div className="game">
          <div className="game-board">
            <Board
              squares={current.squares}
              onClick={(i) => this.handleClick(i)}
            />
          </div>
          <div className="game-info">
            <div>{status}</div>
            <ol>{moves}</ol>
          </div>
        </div>
        <div>
          {/* eslint-disable-next-line no-console */}
          <button type="button" onClick={() => console.log('Implement this button action.')}>
            Save Game
          </button>
        </div>
      </>
    );
  }
}

export default Game;
